import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-432c8200"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "fll flex-y" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "frr" }
const _hoisted_5 = { class: "menu_list" }
const _hoisted_6 = { class: "el-dropdown-link" }
const _hoisted_7 = { class: "el-dropdown-link" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: require('@/assets/image/menu_logo.png'),
        alt: ""
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuData, (item, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (item.children.length > 0)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 'info1' + index,
                  class: _normalizeClass(["menu_item", { current: _ctx.menuPid == item.pid }])
                }, [
                  _createVNode(_component_el_dropdown, { trigger: "click" }, {
                    dropdown: _withCtx(() => [
                      _createVNode(_component_el_dropdown_menu, { class: "dialog" }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (okey, i) => {
                            return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                              key: 'info2' + i,
                              onClick: ($event: any) => (_ctx.toPath(okey.url, item.pid))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(okey.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("p", _hoisted_6, [
                        _createElementVNode("span", null, _toDisplayString(item.pname), 1)
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ], 2))
              : (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["menu_item", { current: _ctx.menuPid == item.pid }]),
                  key: 'info3' + index
                }, [
                  _createElementVNode("p", _hoisted_7, [
                    _createElementVNode("span", {
                      class: "homeText",
                      onClick: ($event: any) => (_ctx.toPath(item.purl, item.pid))
                    }, _toDisplayString(item.pname), 9, _hoisted_8)
                  ])
                ], 2))
          ], 64))
        }), 256))
      ])
    ])
  ]))
}
/*
 * @Author: 申亚芳
 * @Date: 2023-03-31 15:05:57
 * @LastEditors: 申亚芳
 * @LastEditTime: 2024-05-06 17:48:38
 * @Description: file content
 * @FilePath: \cloud-build-website\src\main.ts
 */
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './theme/main-theme.css';
import '@/assets/css/common.less';
import '@/assets/iconfont/iconfont.css';
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import '@/core/http/api_initail.js';
import store from './store';

const app = createApp(App);
//页面每次打开，没有纵向滚动
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
app.use(router);
app.use(ElementPlus);
app.use(store);
app.mount('#app');

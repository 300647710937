<!--
 * @Author: 申亚芳
 * @Date: 2023-03-31 15:05:57
 * @LastEditors: 申亚芳
 * @LastEditTime: 2023-06-08 15:42:09
 * @Description: file content
 * @FilePath: \assemble-explorer\src\App.vue
-->
<template>
  <!-- 
  <keep-alive>
    <router-view :key="$route.fullPath"></router-view>
  </keep-alive>
   -->
  <router-view :key="$route.fullPath"></router-view>
</template>
<script lang="ts">
import { useStore } from 'vuex';
export default {
  name: 'App',
  setup() {
    const store = useStore();
    return {
      store,
    };
  },
  components: {},
  mounted() {
    this.store.commit('setMode', sessionStorage.getItem('themeCurrentValue'));
  },
  methods: {},
};
</script>
<style lang="less">
#app {
  font-family: 'PingFangRegular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: 0;
  margin: 0;
}
</style>

/*
 * @Author: 申亚芳
 * @Date: 2023-03-31 15:05:57
 * @LastEditors: 申亚芳
 * @LastEditTime: 2024-05-14 16:35:22
 * @Description: file content
 * @FilePath: \cloud-build-website\src\router\index.ts
 */
import { RouteRecordRaw, createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'main',
    redirect: '/home',
  },
  //首页-模版页
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue'),
  },
  // //企业碳资产管理
  // {
  //   path: '/carbon-company-manage',
  //   name: 'carbon-company-manage',
  //   component: () => import('../views/carbon-company-manage.vue'),
  // },
  // //双碳咨询服务
  // {
  //   path: '/carbon-double-consult',
  //   name: 'carbon-double-consult',
  //   component: () => import('../views/carbon-double-consult.vue'),
  // },
  // //ESG信息披露
  // {
  //   path: '/public-ESG',
  //   name: 'public-ESG',
  //   component: () => import('../views/public-ESG.vue'),
  // },
  // //绿色企业（项目）评价
  // {
  //   path: '/company-evaluate',
  //   name: 'company-evaluate',
  //   component: () => import('../views/company-evaluate.vue'),
  // },
  // //项目减排量核算服务
  // {
  //   path: '/emission-reduction-check',
  //   name: 'emission-reduction-check',
  //   component: () => import('../views/emission-reduction-check.vue'),
  // },
  // //节能技改一体化金融融资服务
  // {
  //   path: '/financial-asset-service',
  //   name: 'financial-asset-service',
  //   component: () => import('../views/financial-asset-service.vue'),
  // },
  // //绿色项目申报
  // {
  //   path: '/project-apply',
  //   name: 'project-apply',
  //   component: () => import('../views/project-apply.vue'),
  // },
  // //绿色金融产品对接
  // {
  //   path: '/product-join',
  //   name: 'product-join',
  //   component: () => import('../views/product-join.vue'),
  // },
  // //节能技改
  // {
  //   path: '/saving-energy',
  //   name: 'saving-energy',
  //   component: () => import('../views/saving-energy.vue'),
  // },
  // //设备配套
  // {
  //   path: '/equipment-match',
  //   name: 'equipment-match',
  //   component: () => import('../views/equipment-match.vue'),
  // },
  // //碳普惠平台的企业端接入
  // {
  //   path: '/carbon-company-join',
  //   name: 'carbon-company-join',
  //   component: () => import('../views/carbon-company-join.vue'),
  // },
  // //碳普惠方法学开发
  // {
  //   path: '/carbon-method-develop',
  //   name: 'carbon-method-develop',
  //   component: () => import('../views/carbon-method-develop.vue'),
  // },
  // //碳中和大型活动
  // {
  //   path: '/carbon-activity',
  //   name: 'carbon-activity',
  //   component: () => import('../views/carbon-activity.vue'),
  // },
  // //地方政府碳普惠平台开发建设
  // {
  //   path: '/carbon-platform-develop',
  //   name: 'carbon-platform-develop',
  //   component: () => import('../views/carbon-platform-develop.vue'),
  // },
  // //整县推动生态价值实现
  // {
  //   path: '/ecological-value',
  //   name: 'ecological-value',
  //   component: () => import('../views/ecological-value.vue'),
  // },
  //公司介绍
  {
    path: '/company-introduce',
    name: 'company-introduce',
    component: () => import('../views/company-introduce.vue'),
  },
  //联系我们
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('../views/about-us.vue'),
  },
  //绿色培训服务
  {
    path: '/business-train',
    name: 'business-train',
    component: () => import('../views/business-train.vue'),
  },
  //开户咨询
  {
    path: '/open-consult',
    name: 'open-consult',
    component: () => import('../views/open-consult.vue'),
  },
  //绿色咨询
  {
    path: '/consult',
    name: 'consult',
    component: () => import('../views/business-consult.vue'),
  },
  //绿色金融顾问
  {
    path: '/financial-advisor',
    name: 'financial-advisor',
    component: () => import('../views/business-financial-advisor.vue'),
  },
  //节能技改全周期方案设计
  {
    path: '/technology-improve',
    name: 'technology-improve',
    component: () => import('../views/technology-improve.vue'),
  },
  //绿色公益
  {
    path: '/public-benefit',
    name: 'public-benefit',
    component: () => import('../views/public-benefit.vue'),
  },
];
const router = createRouter({
  routes: <RouteRecordRaw[]>routes,
  history: createWebHashHistory(process.env.BASE_URL),
});
router.beforeEach((from, to, next) => {
  next();
});
export default router;

<template>
  <div class="MenuDemoPhone">
    <div class="menuPhone">
      <div class="logo flex-y">
        <img :src="require('@/assets/image/menu_logo.png')" alt="" />
      </div>
      <div class="iconBtns">
        <span
          class="iconfont icon-caidan"
          @click="dialogMenuFlag = true"
        ></span>
      </div>
    </div>
    <!-- 移动端弹出菜单 -->
    <el-collapse-transition>
      <div class="menuPopupShadow" v-if="dialogMenuFlag">
        <div class="contentPopup">
          <div class="top flex-y-s">
            <div class="logoLeft flex-y">
              <img :src="require('@/assets/image/menu_logo.png')" alt="" />
            </div>
            <span
              class="iconfont icon-guanbi"
              @click="dialogMenuFlag = false"
            ></span>
          </div>
          <ul class="menuList">
            <li v-for="(item, index) in menuData" :key="index" class="menuItem">
              <div class="inner flex-y-s" @click="changeMenuFirst(item, index)">
                <div class="flex-y">
                  <span class="name">{{ item.pname }}</span>
                </div>
                <span
                  class="iconfont icon-jiantou-shang"
                  :class="{ rotate: !item.dropDown }"
                  v-if="item.children.length > 0"
                ></span>
              </div>
              <ul
                class="secondList"
                v-show="item.dropDown && item.children.length"
              >
                <li
                  class="secondItem"
                  v-for="(okey, i) in item.children"
                  :key="i"
                >
                  <div class="inner flex-y-s">
                    <div class="flex-y" @click="changeMenuSecond(item, okey)">
                      <span class="name">{{ okey.name }}</span>
                    </div>
                    <!-- 
                    <span
                      @click="toggleDropDown(index, i)"
                      class="iconfont icon-jiantou-shang"
                    ></span>
                     -->
                  </div>
                  <!-- 
                  <ul class="categoryList" v-show="okey.dropDown">
                    <li
                      v-for="(link, i) in okey.children"
                      :key="i"
                      class="pathItem"
                      v-show="link.state == 1"
                    >
                      <div class="category">{{ link.name }}</div>
                      <div class="lists">
                        <div
                          class="path"
                          v-for="(pathObj, j) in link.children"
                          :key="j"
                          @click="
                            changeMenuThird(item.remark, index, pathObj, j)
                          "
                        >
                          {{ pathObj.name }}
                        </div>
                      </div>
                    </li>
                  </ul>
                   -->
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CommonApi } from '../core/http/Api';
import { HttpManager } from '../core/http/HttpManager';
import { HttpRequestConfig } from '../core/http/HttpRequestConfig';
import { HttpRequestParams } from '../core/http/HttpRequestParams';
import { useStore } from 'vuex';
import router from '../router';
export default defineComponent({
  name: 'PAGE_NAME',
  components: {},
  props: {
    // darkColor: {
    //   default: false,
    //   type: Boolean,
    // },
    // darkColorValue: {
    //   default: '#000000',
    //   type: String,
    // },
    // browerFlag: {
    //   default: false,
    //   type: Boolean,
    // },
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const menuData: any = ref([
      {
        pname: '首页',
        pid: 'b_00',
        dropdown: false,
        purl: '/',
        children: [],
      },
      // {
      //   pname: '碳咨询',
      //   pid: 'a_00',
      //   dropdown: false,
      //   purl: 'null',
      //   children: [
      //     {
      //       name: '企业碳资产管理',
      //       id: 'a_00_01',
      //       url: '/carbon-company-manage',
      //     },
      //     {
      //       name: '双碳咨询服务',
      //       id: 'a_00_02',
      //       url: '/carbon-double-consult',
      //     },
      //     {
      //       name: 'ESG信息披露',
      //       id: 'a_00_03',
      //       url: '/public-ESG',
      //     },
      //   ],
      // },
      // {
      //   pname: '碳金融',
      //   pid: 'a_01',
      //   dropdown: false,
      //   purl: null,
      //   children: [
      //     {
      //       name: '绿色企业（项目）评价',
      //       id: 'a_01_01',
      //       url: '/company-evaluate',
      //     },
      //     {
      //       name: '项目减排量核算服务',
      //       id: 'a_01_02',
      //       url: '/emission-reduction-check',
      //     },
      //     {
      //       name: '节能技改一体化金融融资服务',
      //       id: 'a_01_03',
      //       url: '/financial-asset-service',
      //     },
      //     {
      //       name: '绿色项目申报',
      //       id: 'a_01_04',
      //       url: '/project-apply',
      //     },
      //     {
      //       name: '绿色金融产品对接',
      //       id: 'a_01_05',
      //       url: '/product-join',
      //     },
      //   ],
      // },
      // {
      //   pname: '节能技改',
      //   pid: 'a_02',
      //   dropdown: false,
      //   purl: '/saving-energy',
      //   children: [],
      // },
      // {
      //   pname: '设备配套',
      //   pid: 'a_03',
      //   dropdown: false,
      //   purl: '/equipment-match',
      //   children: [],
      // },
      // {
      //   pname: '碳普惠',
      //   pid: 'a_04',
      //   dropdown: false,
      //   purl: null,
      //   children: [
      //     {
      //       name: '碳普惠平台的企业端接入',
      //       id: 'a_04_01',
      //       url: '/carbon-company-join',
      //     },
      //     {
      //       name: '碳普惠方法学开发',
      //       id: 'a_04_02',
      //       url: '/carbon-method-develop',
      //     },
      //     {
      //       name: '碳中和大型活动',
      //       id: 'a_04_03',
      //       url: '/carbon-activity',
      //     },
      //     {
      //       name: '地方政府碳普惠平台开发建设',
      //       id: 'a_04_04',
      //       url: '/carbon-platform-develop',
      //     },
      //     {
      //       name: '整县推动生态价值实现',
      //       id: 'a_04_05',
      //       url: '/ecological-value',
      //     },
      //   ],
      // },
      {
        pname: '公司介绍',
        pid: 'a_06',
        dropdown: false,
        purl: '/company-introduce',
        children: [],
      },
      {
        pname: '业务体系',
        pid: 'a_07',
        dropdown: false,
        purl: '',
        children: [
          {
            name: '绿色培训服务',
            id: 'a_07_01',
            url: '/business-train',
          },
          {
            name: '开户咨询',
            id: 'a_07_02',
            url: '/open-consult',
          },
          {
            name: '绿色咨询',
            id: 'a_07_03',
            url: '/consult',
          },
          {
            name: '绿色金融顾问',
            id: 'a_07_04',
            url: '/financial-advisor',
          },
          {
            name: '节能技改全周期方案设计',
            id: 'a_07_05',
            url: '/technology-improve',
          },
          {
            name: '绿色公益',
            id: 'a_07_06',
            url: '/public-benefit',
          },
        ],
      },
      {
        pname: '联系我们',
        pid: 'a_05',
        dropdown: false,
        purl: '/about-us',
        children: [],
      },
    ]);
    const dialogMenuFlag: any = ref(false);
    return {
      router,
      route,
      store,
      menuData,
      dialogMenuFlag,
    };
  },
  created() {},
  methods: {
    changeTab(index: number) {
      this.phoneTabsActive = index;
    },
    toggleDropDown(firstIndex: number, secondIndex: number) {
      this.menuData[firstIndex].children.forEach((e: any, i: number) => {
        e.dropDown = secondIndex == i ? true : false;
      });
    },
    //点击一级菜单
    changeMenuFirst(item: any, index: any) {
      sessionStorage.setItem('pid', item.pid);
      if (item.children.length == 0) {
        this.dialogMenuFlag = false;
        this.router.push(item.purl);
      }
      if (item.children.length > 0) {
        this.menuData.forEach((e: any, i: number) => {
          e.dropDown = index == i ? !e.dropDown : false;
        });
      }
    },
    //点击二级菜单
    changeMenuSecond(item: any, okey: any) {
      sessionStorage.setItem('pid', item.pid);
      this.dialogMenuFlag = false;
      this.router.push(okey.url);
    },
    //点击三级菜单
    changeMenuThird() {},
    //当前窗口跳转、新标签页跳转
    pathOpen(item: any, category: string) {
      //linkType: 1, //链接类型 0无链接 1内部链接 2自定义链接
      //jumbType: 0, //跳转方式 0 当前窗口 1新标签页面
      switch (item.linkType) {
        case 1:
          //内部链接1
          if (item.jumbType == 1) {
            //新标签页面1
            //vue项目打开新窗口
            let path = item.path.substring(1, item.path.length);
            if (category == '1') {
              //一级菜单跳转
              let page = this.router.resolve({
                name: path,
                // query: {id: id, e:0}
              });
              window.open(page.href, '_blank');
            } else if (category == '2') {
              //二级菜单跳转（区块链相关页面跳转）
              let page = this.router.resolve({
                name: path,
                query: { chainId: item.chainId },
              });
              window.open(page.href, '_blank');
            }
          } else {
            //当前窗口
            if (category == '1') {
              //一级菜单跳转
              this.router.push(item.path); //覆盖当前页面
            } else if (category == '2') {
              //二级菜单跳转（区块链相关页面跳转）
              this.router.push({
                path: item.path,
                query: { chainId: item.chainId },
              });
            }
          }
          break;
        case 2:
          //自定义链接2
          if (item.jumbType == 1) {
            //新标签页面1
            window.open(item.path); //打开新窗口
          } else {
            //当前窗口
            window.location.href = item.path; //覆盖当前页面
          }
          break;
        default:
          //无链接0
          this.$message('开发中...');
          // return;
          break;
      }
    },
  },
});
</script>
<style lang="less" scoped>
.MenuDemoPhone {
  width: 100%;
  height: 100%;
}
.menuPhone {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  .logo {
    img {
      display: block;
      width: 118px;
      height: 34px;
      margin-right: 6px;
    }
    span {
      color: rgba(13, 54, 102, 1);
      font-size: 20px;
      font-weight: bold;
    }
  }
  .iconBtns {
    span.iconfont {
      color: #0d3666;
      font-size: 20px;
      margin-left: 12px;
      &.icon-sousuo {
        color: #0075ff;
      }
    }
  }
}
.searchPhone {
  width: 100vw;
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  box-sizing: border-box;
  z-index: 100;
  .icon-jiantouyou {
    color: #fff;
    font-size: 20px;
  }
  .inputBox {
    flex: 1;
    height: 32px;
    background: rgba(0, 117, 255, 0.06);
    border-radius: 8px;
    padding: 0 8px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 117, 255, 0.3);
    display: flex;
    align-items: center;
    overflow-x: hidden;
    img.chainLogo {
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
    }
    .line {
      width: 1px;
      height: 16px;
      background: rgba(13, 54, 102, 0.2);
      margin-left: 6px;
      margin-right: 6px;
    }
    span.icon-sousuo {
      font-size: 18px;
      color: rgba(0, 117, 255, 0.4);
      margin-right: 4px;
      cursor: pointer;
    }
    /deep/ .el-input {
      width: 260px;
    }
    /deep/ .el-input__wrapper {
      padding: 0;
      background: transparent;
      box-shadow: none;
    }
    /deep/ .el-input__inner {
      color: rgba(13, 54, 102, 0.6);
      &::-webkit-input-placeholder {
        color: rgba(13, 54, 102, 0.4);
      }
      &:-moz-placeholder {
        color: rgba(13, 54, 102, 0.4);
      }
      &:-ms-input-placeholder {
        color: rgba(13, 54, 102, 0.4);
      }
    }
  }
  .searchBox {
    flex: 0 0 47px;
    height: 32px;
    background: #0075ff;
    border-radius: 0px 8px 8px 0px;
    margin-left: -6px;
  }
}
.darkColorMenuWrap {
  .logo {
    span {
      color: #fff;
    }
  }
  .iconBtns {
    span.iconfont {
      color: #fff;
      &.icon-sousuo {
        color: #0075ff;
      }
    }
  }
  //搜索框
  .icon-jiantouyou {
    color: #fff;
  }
  .inputBox {
    background: #fff;
    border: 1px solid rgba(0, 117, 255, 0.1);
    .line {
      background: rgba(13, 54, 102, 0.1);
    }
  }
}
//菜单弹框
.menuPopupShadow {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  z-index: 9999;
  display: flex;
  justify-content: flex-end;
  .contentPopup {
    // width: 86%;
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: hidden;
    box-sizing: border-box;
    padding: 20px;
    overflow-y: auto;
    .top {
      margin-bottom: 20px;
      .logoLeft {
        img {
          width: 100px;
          height: 34px;
          margin-right: 6px;
        }
        span {
          font-size: 18px;
          color: #0d3666;
          font-weight: bold;
        }
      }
      span.icon-guanbi {
        font-size: 32px;
        font-weight: bold;
        color: #0d3666;
      }
    }
    .tabs {
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(13, 54, 102, 0.1);
      span {
        margin-right: 16px;
        font-size: 16px;
        color: rgba(13, 54, 102, 0.6);
        font-weight: 400;
        &.active {
          font-weight: 600;
          color: #0075ff;
          position: relative;
          &:after {
            content: '';
            width: 50%;
            height: 2px;
            background: #0075ff;
            position: absolute;
            left: 25%;
            bottom: -12px;
          }
        }
      }
    }
    ul.menuList {
      li.menuItem {
        border-bottom: 1px solid #ebebeb;
        &:last-child {
          border-bottom: none;
        }
        .inner {
          padding-top: 15px;
          padding-bottom: 15px;
          img.logo {
            display: block;
            width: 24px;
            height: 24px;
            margin-right: 4px;
          }
          span.name {
            font-size: 16px;
            font-weight: 400;
            color: #0d3666;
          }
          span.icon-jiantou-shang {
            color: rgba(13, 54, 102, 0.6);
            font-size: 24px;
            transform: rotate(180deg);
            transition: all ease 0.3s;
            &.rotate {
              transform: rotate(90deg);
            }
          }
        }

        ul.categoryList {
          width: 100%;
          background: #f8fbff;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #f0f5fa;
          box-sizing: border-box;
          padding: 12px;
          li.pathItem {
            margin-bottom: 8px;
            .category {
              font-size: 14px;
              font-weight: 600;
              color: rgba(13, 54, 102, 0.4);
              margin-bottom: 8px;
            }
            .lists {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              gap: 6px;
              .path {
                font-size: 12px;
                font-weight: 400;
                color: #0d3666;
              }
            }
          }
        }
      }
    }
  }
}
//设置弹框
.settingPopupShadow {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  z-index: 9999;
  display: flex;
  justify-content: flex-end;
  .contentPopup {
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: hidden;
    box-sizing: border-box;
    padding: 20px;
    .top {
      margin-bottom: 20px;
      .logoLeft {
        img {
          width: 154px;
          margin-right: 6px;
        }
        span {
          font-size: 18px;
          color: #0d3666;
          font-weight: bold;
        }
      }
      span.icon-guanbi {
        font-size: 32px;
        font-weight: bold;
        color: #0d3666;
      }
    }
    ul.settingList {
      li.settingItem {
        margin-bottom: 12px;
        .innerli {
          padding-top: 8px;
          padding-bottom: 8px;
          .left {
            font-size: 16px;
            font-weight: 400;
            color: rgba(13, 54, 102, 0.6);
            span.iconfont {
              margin-right: 4px;
              color: #0d3666;
              font-size: 20px;
            }
          }
          .right {
            .text {
              font-size: 14px;
              font-weight: 600;
              color: #0075ff;
            }
            span.iconfont {
              margin-left: 4px;
              font-size: 20px;
              color: #0d3666;
            }
          }
        }
        ul.settingCategory {
          width: 100%;
          background: #f8fbff;
          border-radius: 8px 8px 8px 8px;
          border: 1px solid #f0f5fa;
          box-sizing: border-box;
          padding: 12px;
          li.categoryLi {
            margin-bottom: 4px;
            font-size: 14px;
            font-weight: 400;
            color: #0d3666;
            padding-top: 6px;
            padding-bottom: 6px;
            span {
              // transform: scale(0.92);
            }
          }
          li.active {
            color: #0075ff;
          }
        }
      }
    }
    .divideWrap {
      span {
        color: rgba(13, 54, 102, 0.4);
        font-size: 15px;
        padding-right: 10px;
      }
      .line {
        flex: 1;
        height: 1px;
        background: rgba(13, 54, 102, 0.1);
      }
    }
    ul.modeList {
      li.modeItem {
        width: 100%;
        font-size: 16px;
        font-weight: 400;
        color: rgba(13, 54, 102, 0.6);
        padding: 8px 0;
        span.iconfont {
          margin-right: 4px;
          color: #0d3666;
          font-size: 20px;
        }
      }
      li.active {
        color: #0075ff;
        span.iconfont {
          color: #0075ff;
        }
      }
    }
  }
}
</style>

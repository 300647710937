<!--
 * @Author: 申亚芳
 * @Date: 2023-03-31 15:05:57
 * @LastEditors: 申亚芳
 * @LastEditTime: 2024-05-16 16:34:04
 * @Description: file content
 * @FilePath: \cloud-build-website\src\views\company-introduce.vue
-->
<template>
  <div class="page-content">
    <div class="menu-content pc">
      <MenuDemoPC></MenuDemoPC>
    </div>
    <div class="menu-content phone">
      <MenuDemoPhone></MenuDemoPhone>
    </div>
    <div class="details">
      <div class="container">
        <div class="title_h4">
          <span>公司介绍</span>
          <i></i>
        </div>
        <!-- <div class="detailsImages">
          <img :src="require('@/assets/image/detail_img1.png')" alt="" />
          <img :src="require('@/assets/image/detail_img2.png')" alt="" />
          <img :src="require('@/assets/image/detail_img3.png')" alt="" />
        </div> -->
        <div class="detailsContent">
          <!-- <div class="title_h1">控排企业（试点+全国）</div> -->
          <div class="detailsPPText">
            <p>网雀湖北科技有限公司是一家专业从事碳产业研发、服务机构。</p>
            <p>
              公司成立以来积极布局国内自主碳市场的开发和服务， 并快速向 CCER
              项目开发、 碳核查、 碳排放管理体系建设、 碳顾问、
              碳培训等领域拓展。
              引入科研院校、金融保险、财税合规、评估审计、法律咨询、人力资源等机构，
              构建包括规划咨询、 研发设计、
              设备代采、技术运维、工程建设、运营管理、 投融资服务等全产业链，
              覆盖节能环保的各细分市场。
            </p>
            <p>
              公司依托专业团队， 整合节能减排生态圈， 全面对接需求、 政策、
              技术、 资金、 专业服务和公益宣传， 同时关注碳中和行业顶层设计，
              探索低碳发展技术路线， 引领科技创新和产业变革，
              透析未来国际投资的绿色化动向，具备为绿色发展提供综合解决方案的能力。
            </p>
            <p>
              网雀科技以切入行业痛点、 展开行业前瞻为导向，
              按照新质生产力发展要求， 向企业和公众倡导绿色生产生活方式，
              致力于搭建一个中国对话世界、 企业对话政府、
              对内凝聚碳中和社会共识、 对外讲好碳中和中国故事的交流平台。
              共谋绿色发展， 共创低碳生活， 走向中和之路！
            </p>
          </div>
          <!-- 
          <div class="title_h1">
            非控排企业（石化、化工、建材、钢铁、水泥、有色、造纸、民航）
          </div>
           -->
          <!-- <div class="detailsPPText">
            1、配额试算：根据企业排放量提供配额测算工作；<br />
            2、数据报送：指导企业填报排放数据；<br />
            3、能力建设：为企业提供一年3次能力建设培训服务；<br />
            4、碳盘查：对有需求的企业提供碳盘查服务；<br />
          </div>
           -->
          <!-- 
          <div class="title_h2">
            源自&nbsp;&nbsp;云链动态&nbsp;&nbsp;2019-02-24
          </div>
           -->

          <!--           
          <img
            :src="require('@/assets/image/detail_main_img.png')"
            alt=""
            class="detailsMainImg"
          /> 
        --></div>
      </div>
    </div>
    <FooterDemo></FooterDemo>
  </div>
</template>
<script lang="ts">
/* eslint-disable */
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CommonApi } from '../core/http/Api';
import { HttpManager } from '../core/http/HttpManager';
import { HttpRequestConfig } from '../core/http/HttpRequestConfig';
import { HttpRequestParams } from '../core/http/HttpRequestParams';
import FooterDemo from '@/components/FooterDemo.vue';
import MenuDemoPC from '@/components/MenuDemoPC.vue';
import MenuDemoPhone from '@/components/MenuDemoPhone.vue';
export default defineComponent({
  name: 'page',
  components: {
    FooterDemo,
    MenuDemoPC,
    MenuDemoPhone,
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  created() {},
  mounted() {},
  methods: {},
  unmounted() {},
});
</script>
<style lang="less" scoped>
.page-content {
  .detailsContent {
    .detailsPPText {
      width: 960px;
      margin: auto;
      font-size: 21px;
      line-height: 1.8em;
      padding-bottom: 60px;
      @media screen and (max-width: 750px) {
        width: 100%;
        padding-bottom: 30px;
      }
    }
  }
}
</style>

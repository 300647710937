/*
 * @Author: 申亚芳
 * @Date: 2023-03-31 15:05:57
 * @LastEditors: 申亚芳
 * @LastEditTime: 2023-06-08 15:06:32
 * @Description: file content
 * @FilePath: \assemble-explorer\src\store\index.ts
 */
import Vuex from 'vuex';

export default new Vuex.Store({
  state: {
    mode: 'light',
  },
  getters: {},
  mutations: {
    setMode(state, data) {
      state.mode = data;
    },
  },
  actions: {},
});

<!--
 * @Author: 申亚芳
 * @Date: 2023-04-07 15:50:31
 * @LastEditors: 申亚芳
 * @LastEditTime: 2024-05-16 11:21:52
 * @Description: file content
 * @FilePath: \cloud-build-website\src\components\MenuDemoPC.vue
-->
<template>
  <div class="container">
    <div class="fll flex-y">
      <img :src="require('@/assets/image/menu_logo.png')" alt="" />
    </div>
    <div class="frr">
      <div class="menu_list">
        <template v-for="(item, index) in menuData">
          <div
            v-if="item.children.length > 0"
            :key="'info1' + index"
            class="menu_item"
            :class="{ current: menuPid == item.pid }"
          >
            <el-dropdown trigger="click">
              <p class="el-dropdown-link">
                <span> {{ item.pname }} </span>
                <!-- <img src="../../assets/img/down.png" /> -->
              </p>
              <template #dropdown>
                <el-dropdown-menu class="dialog">
                  <el-dropdown-item
                    v-for="(okey, i) in item.children"
                    :key="'info2' + i"
                    @click.native="toPath(okey.url, item.pid)"
                    >{{ okey.name }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <div
            v-else
            class="menu_item"
            :key="'info3' + index"
            :class="{ current: menuPid == item.pid }"
          >
            <p class="el-dropdown-link">
              <span class="homeText" @click="toPath(item.purl, item.pid)">{{
                item.pname
              }}</span>
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CommonApi } from '../core/http/Api';
import { HttpManager } from '../core/http/HttpManager';
import { HttpRequestConfig } from '../core/http/HttpRequestConfig';
import { HttpRequestParams } from '../core/http/HttpRequestParams';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'PAGE_NAME',
  components: {},
  // props: {
  //   darkColor: {
  //     default: false,
  //     type: Boolean,
  //   },
  //   browerFlag: {
  //     default: false,
  //     type: Boolean,
  //   },
  // },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const menuPid: any = ref(sessionStorage.getItem('pid') ?? 'b_00'); //默认是首页的pid
    const menuData: any = ref([
      {
        pname: '首页',
        pid: 'b_00',
        dropdown: false,
        purl: '/',
        children: [],
      },
      // {
      //   pname: '碳咨询',
      //   pid: 'a_00',
      //   dropdown: false,
      //   purl: 'null',
      //   children: [
      //     {
      //       name: '企业碳资产管理',
      //       id: 'a_00_01',
      //       url: '/carbon-company-manage',
      //     },
      //     {
      //       name: '双碳咨询服务',
      //       id: 'a_00_02',
      //       url: '/carbon-double-consult',
      //     },
      //     {
      //       name: 'ESG信息披露',
      //       id: 'a_00_03',
      //       url: '/public-ESG',
      //     },
      //   ],
      // },
      // {
      //   pname: '碳金融',
      //   pid: 'a_01',
      //   dropdown: false,
      //   purl: null,
      //   children: [
      //     {
      //       name: '绿色企业（项目）评价',
      //       id: 'a_01_01',
      //       url: '/company-evaluate',
      //     },
      //     {
      //       name: '项目减排量核算服务',
      //       id: 'a_01_02',
      //       url: '/emission-reduction-check',
      //     },
      //     {
      //       name: '节能技改一体化金融融资服务',
      //       id: 'a_01_03',
      //       url: '/financial-asset-service',
      //     },
      //     {
      //       name: '绿色项目申报',
      //       id: 'a_01_04',
      //       url: '/project-apply',
      //     },
      //     {
      //       name: '绿色金融产品对接',
      //       id: 'a_01_05',
      //       url: '/product-join',
      //     },
      //   ],
      // },
      // {
      //   pname: '节能技改',
      //   pid: 'a_02',
      //   dropdown: false,
      //   purl: '/saving-energy',
      //   children: [],
      // },
      // {
      //   pname: '设备配套',
      //   pid: 'a_03',
      //   dropdown: false,
      //   purl: '/equipment-match',
      //   children: [],
      // },
      // {
      //   pname: '碳普惠',
      //   pid: 'a_04',
      //   dropdown: false,
      //   purl: null,
      //   children: [
      //     {
      //       name: '碳普惠平台的企业端接入',
      //       id: 'a_04_01',
      //       url: '/carbon-company-join',
      //     },
      //     {
      //       name: '碳普惠方法学开发',
      //       id: 'a_04_02',
      //       url: '/carbon-method-develop',
      //     },
      //     {
      //       name: '碳中和大型活动',
      //       id: 'a_04_03',
      //       url: '/carbon-activity',
      //     },
      //     {
      //       name: '地方政府碳普惠平台开发建设',
      //       id: 'a_04_04',
      //       url: '/carbon-platform-develop',
      //     },
      //     {
      //       name: '整县推动生态价值实现',
      //       id: 'a_04_05',
      //       url: '/ecological-value',
      //     },
      //   ],
      // },
      {
        pname: '公司介绍',
        pid: 'a_06',
        dropdown: false,
        purl: '/company-introduce',
        children: [],
      },
      {
        pname: '业务体系',
        pid: 'a_07',
        dropdown: false,
        purl: '',
        children: [
          {
            name: '绿色培训服务',
            id: 'a_07_01',
            url: '/business-train',
          },
          {
            name: '开户咨询',
            id: 'a_07_02',
            url: '/open-consult',
          },
          {
            name: '绿色咨询',
            id: 'a_07_03',
            url: '/consult',
          },
          {
            name: '绿色金融顾问',
            id: 'a_07_04',
            url: '/financial-advisor',
          },
          {
            name: '节能技改全周期方案设计',
            id: 'a_07_05',
            url: '/technology-improve',
          },
          {
            name: '绿色公益',
            id: 'a_07_06',
            url: '/public-benefit',
          },
        ],
      },
      {
        pname: '联系我们',
        pid: 'a_05',
        dropdown: false,
        purl: '/about-us',
        children: [],
      },
    ]);
    return {
      router,
      route,
      store,
      menuPid,
      menuData,
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    //路由跳转
    toPath(url: any, pid: String) {
      url = url.replace(/\s*/g, '');
      if (!url) {
        this.$message({
          message: '正在开发中...',
          duration: 1200,
        });
        return false;
      }
      this.menuPid = pid;
      // if (index != undefined && index != 'undefined') {
      //   this.menuPid = index;
      // }
      sessionStorage.setItem('pid', this.menuPid);
      this.$router.push(url);
    },
  },
});
</script>
<style lang="less" scoped>
.container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fll {
  width: 198px;
  height: 57px;
  img {
    width: 100%;
    height: 100%;
  }
}
.frr {
  flex: 1;
  width: calc(100% - 198px);
  .menu_list {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .menu_item {
      margin-right: 50px;
      .el-dropdown-link {
        color: #606972;
        span {
          display: inline-block;
          color: #606972;
          font-size: 16px;
        }
        img {
          margin-left: 4px;
        }
      }
      &.current {
        .el-dropdown-link {
          span {
            font-size: 16px;
            color: #257e16;
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>

<!--
 * @Author: 申亚芳
 * @Date: 2023-03-31 15:05:57
 * @LastEditors: 申亚芳
 * @LastEditTime: 2024-05-14 17:27:37
 * @Description: file content
 * @FilePath: \cloud-build-website\src\views\about-us.vue
-->
<template>
  <div class="page-content">
    <div class="menu-content pc">
      <MenuDemoPC></MenuDemoPC>
    </div>
    <div class="menu-content phone">
      <MenuDemoPhone></MenuDemoPhone>
    </div>
    <div class="details">
      <div class="container">
        <div class="title_h4">
          <span>联系我们</span>
          <i></i>
        </div>
        <div class="contactUs">
          <div class="container">
            <div class="content">
              <div class="images">
                <img :src="require('@/assets/image/contact_us_img1.png')" />
              </div>
              <div class="frr">
                <div class="title">联系我们</div>
                <div class="mywrap">
                  <div class="texts">
                    <div class="item flex-y">
                      <span class="flex-y">
                        <img
                          :src="require('@/assets/image/company_name.png')"
                          alt=""
                        />
                        <b>公司名称</b>
                      </span>
                      <i>网雀湖北科技有限公司</i>
                    </div>
                    <div class="item flex-y">
                      <span class="flex-y">
                        <img
                          :src="require('@/assets/image/company_tel.png')"
                          alt=""
                        />
                        <b>联系电话</b>
                      </span>
                      <i>027-85518717</i>
                    </div>
                    <div class="item flex-y">
                      <span class="flex-y">
                        <img
                          :src="require('@/assets/image/company_email.png')"
                          alt=""
                        />
                        <b>联系邮箱</b>
                      </span>
                      <i>vinni999@sina.com</i>
                    </div>
                    <div class="item flex-y">
                      <span class="flex-y">
                        <img
                          :src="require('@/assets/image/company_website.png')"
                          alt=""
                        />
                        <b>公司网址</b>
                      </span>
                      <i>http://www.wq-ai.com</i>
                    </div>
                    <div class="item flex-y">
                      <span class="flex-y">
                        <img
                          :src="require('@/assets/image/company_address.png')"
                          alt=""
                        />
                        <b>公司地址</b>
                      </span>
                      <i>武汉市武昌区中北路223号中碳登大厦10楼</i>
                    </div>
                  </div>
                  <div class="qrCode">
                    <div class="code">
                      <img :src="require('@/assets/image/code1.jpg')" alt="" />
                      <span>碳圯</span>
                    </div>
                    <div class="code">
                      <img :src="require('@/assets/image/code2.jpg')" alt="" />
                      <span>夸夸奇碳</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterDemo></FooterDemo>
  </div>
</template>
<script lang="ts">
/* eslint-disable */
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CommonApi } from '../core/http/Api';
import { HttpManager } from '../core/http/HttpManager';
import { HttpRequestConfig } from '../core/http/HttpRequestConfig';
import { HttpRequestParams } from '../core/http/HttpRequestParams';
import FooterDemo from '@/components/FooterDemo.vue';
import MenuDemoPC from '@/components/MenuDemoPC.vue';
import MenuDemoPhone from '@/components/MenuDemoPhone.vue';
export default defineComponent({
  name: 'page',
  components: {
    FooterDemo,
    MenuDemoPC,
    MenuDemoPhone,
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  created() {},
  mounted() {},
  methods: {},
  unmounted() {},
});
</script>
<style lang="less" scoped>
.page-content {
  .contactUs {
    width: 100%;
    margin-bottom: 44px;
    @media screen and (max-width: 750px) {
      margin-bottom: 22px;
    }
    .content {
      display: flex;
      @media screen and (max-width: 750px) {
        display: unset;
      }
      .images {
        width: 360px;
        height: 270px;
        border-radius: 4px;
        overflow: hidden;
        @media screen and (max-width: 750px) {
          width: 100%;
          height: auto;
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .frr {
        flex: 1;
        box-sizing: border-box;
        padding: 30px 0 30px 60px;
        @media screen and (max-width: 750px) {
          padding: 30px 0 30px 0px;
        }
        .title {
          font-weight: 500;
          font-size: 16px;
          color: #1d2023;
          margin-bottom: 30px;
        }
        .mywrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding-right: 280px;
          @media screen and (max-width: 750px) {
            display: unset;
            padding-right: 0;
          }
          .texts {
            .item {
              display: flex;
              align-items: center;
              margin-bottom: 22px;
              span {
                width: 76px;
                margin-right: 28px;
                @media screen and (max-width: 750px) {
                  margin-right: 10px;
                  min-width: 76px;
                }
                img {
                  width: 14px;
                  height: 14px;
                  margin-right: 4px;
                }
              }
              i {
                font-weight: 400;
                font-size: 14px;
                color: #000000;
              }
            }
          }
          .qrCode {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @media screen and (max-width: 750px) {
              justify-content: center;
            }
            .code {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-left: 12px;
              @media screen and (max-width: 750px) {
                margin-left: 0;
              }
              img {
                width: 92px;
                height: 92px;
                margin-bottom: 14px;
              }
              span {
                font-size: 12px;
                color: #535353;
              }
            }
          }
        }
      }
    }
  }
}
</style>

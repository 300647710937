/*
 * @Author: 申亚芳
 * @Date: 2022-09-01 13:42:49
 * @LastEditors: 申亚芳
 * @LastEditTime: 2023-06-02 11:37:32
 * @Description: file content
 * @FilePath: \assemble-explorer\src\core\http\api_initail.js
 */
import {CommonApi} from './Api'
console.log('===BaseURL====:' + CommonApi.BaseUrl)
if (process.env.NODE_ENV === 'development') {
    // 开发环境(配置代理的时候，就不能配置这个)
    CommonApi.BaseUrl  = 'http://192.168.0.99:8108';
} else if (process.env.NODE_ENV === 'production') {
   // 生产环境
    CommonApi.BaseUrl  = 'http://aggre.ylzh.pro';
} else {
    // 测试环境
    CommonApi.BaseUrl  = 'http://192.168.0.99:8108';
}
console.log(process.env)
console.log('BaseURL_API配置文件:' + CommonApi.BaseUrl)
/*
 * @Author: 申亚芳
 * @Date: 2022-08-09 17:16:24
 * @LastEditors: 申亚芳
 * @LastEditTime: 2024-05-06 17:37:42
 * @Description: file content
 * @FilePath: \cloud-build-website\src\core\http\Api.ts
 */
export enum ApiType {
  CommonApi,
}
export class Api {
  private static _instance: Api;
  public static get instance() {
    if (!this._instance) {
      this._instance = Api.factory();
    } else {
      console.log('lazy loading singleton has created');
    }
    return this._instance;
  }

  static factory(type?: ApiType) {
    return new Api();
  }

  get fullPath(): string {
    return '';
  }
}

export enum NameSpaceType {
  login,
}

export class CommonApi {
  // 环境的切换
  static BaseUrl = '';
  static BlockChUrl = '';
  static wsUrl = '';

  /////块hash与交易hash的查询-百度超级链
  static get url_xSuperChain_chain_browser(): string {
    return CommonApi.BaseUrl + '/xSuperChain/chain_browser/getTx';
  }

}

<!--
 * @Author: 申亚芳
 * @Date: 2023-03-31 15:05:57
 * @LastEditors: 申亚芳
 * @LastEditTime: 2024-05-15 17:09:37
 * @Description: file content
 * @FilePath: \cloud-build-website\src\views\public-benefit.vue
-->
<template>
  <div class="page-content">
    <div class="menu-content pc">
      <MenuDemoPC></MenuDemoPC>
    </div>
    <div class="menu-content phone">
      <MenuDemoPhone></MenuDemoPhone>
    </div>
    <div class="details">
      <div class="container">
        <div class="title_h4">
          <span>绿色公益</span>
          <i></i>
        </div>
        <!-- 
        <div class="infoTitle">
          <span class="left"></span> <i>绿色公益</i> <span class="right"></span>
        </div>
         -->
        <img :src="require('@/assets/image/lsgy_img.png')" class="lsgyImg" />
        <!-- 
        <div class="detailsImages">
          <img :src="require('@/assets/image/detail_img1.png')" alt="" />
          <img :src="require('@/assets/image/detail_img2.png')" alt="" />
          <img :src="require('@/assets/image/detail_img3.png')" alt="" />
        </div>
        <div class="detailsContent">
          <div class="title_h1"><i></i>知识大赛</div>
          <div class="title_h1"><i></i>低碳活动</div>
          <div class="title_h1"><i></i>低碳会议</div>
          <div class="title_h1">
            全市论坛展会、大型会议、体育赛事、活动演出等主办单位绿色办会
          </div>
          <div class="detailsPPText">
            使用碳普惠减排量实现大型活动/赛事/会议碳中和，出具碳中和证书。
          </div>
          <div class="title_h2">
            源自&nbsp;&nbsp;云链动态&nbsp;&nbsp;2019-02-24
          </div>
          <img
            :src="require('@/assets/image/detail_main_img.png')"
            alt=""
            class="detailsMainImg"
          />
        </div>
        -->
      </div>
    </div>
    <FooterDemo></FooterDemo>
  </div>
</template>
<script lang="ts">
/* eslint-disable */
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CommonApi } from '../core/http/Api';
import { HttpManager } from '../core/http/HttpManager';
import { HttpRequestConfig } from '../core/http/HttpRequestConfig';
import { HttpRequestParams } from '../core/http/HttpRequestParams';
import FooterDemo from '@/components/FooterDemo.vue';
import MenuDemoPC from '@/components/MenuDemoPC.vue';
import MenuDemoPhone from '@/components/MenuDemoPhone.vue';
export default defineComponent({
  name: 'page',
  components: {
    FooterDemo,
    MenuDemoPC,
    MenuDemoPhone,
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  created() {},
  mounted() {},
  methods: {},
  unmounted() {},
});
</script>
<style lang="less" scoped>
.page-content {
  .details {
    padding-bottom: 80px;
    img.lsgyImg {
      display: block;
      width: 900px;
      margin: auto;
      @media screen and (max-width: 750px) {
        max-width: 100%;
      }
    }
  }
}
</style>

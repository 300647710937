import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19c09e5a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "MenuDemoPhone" }
const _hoisted_2 = { class: "menuPhone" }
const _hoisted_3 = { class: "logo flex-y" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "iconBtns" }
const _hoisted_6 = {
  key: 0,
  class: "menuPopupShadow"
}
const _hoisted_7 = { class: "contentPopup" }
const _hoisted_8 = { class: "top flex-y-s" }
const _hoisted_9 = { class: "logoLeft flex-y" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "menuList" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "flex-y" }
const _hoisted_14 = { class: "name" }
const _hoisted_15 = { class: "secondList" }
const _hoisted_16 = { class: "inner flex-y-s" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_collapse_transition = _resolveComponent("el-collapse-transition")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: require('@/assets/image/menu_logo.png'),
          alt: ""
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("span", {
          class: "iconfont icon-caidan",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialogMenuFlag = true))
        })
      ])
    ]),
    _createVNode(_component_el_collapse_transition, null, {
      default: _withCtx(() => [
        (_ctx.dialogMenuFlag)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("img", {
                      src: require('@/assets/image/menu_logo.png'),
                      alt: ""
                    }, null, 8, _hoisted_10)
                  ]),
                  _createElementVNode("span", {
                    class: "iconfont icon-guanbi",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dialogMenuFlag = false))
                  })
                ]),
                _createElementVNode("ul", _hoisted_11, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuData, (item, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: index,
                      class: "menuItem"
                    }, [
                      _createElementVNode("div", {
                        class: "inner flex-y-s",
                        onClick: ($event: any) => (_ctx.changeMenuFirst(item, index))
                      }, [
                        _createElementVNode("div", _hoisted_13, [
                          _createElementVNode("span", _hoisted_14, _toDisplayString(item.pname), 1)
                        ]),
                        (item.children.length > 0)
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: _normalizeClass(["iconfont icon-jiantou-shang", { rotate: !item.dropDown }])
                            }, null, 2))
                          : _createCommentVNode("", true)
                      ], 8, _hoisted_12),
                      _withDirectives(_createElementVNode("ul", _hoisted_15, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (okey, i) => {
                          return (_openBlock(), _createElementBlock("li", {
                            class: "secondItem",
                            key: i
                          }, [
                            _createElementVNode("div", _hoisted_16, [
                              _createElementVNode("div", {
                                class: "flex-y",
                                onClick: ($event: any) => (_ctx.changeMenuSecond(item, okey))
                              }, [
                                _createElementVNode("span", _hoisted_18, _toDisplayString(okey.name), 1)
                              ], 8, _hoisted_17)
                            ])
                          ]))
                        }), 128))
                      ], 512), [
                        [_vShow, item.dropDown && item.children.length]
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}
<!--
 * @Author: 申亚芳
 * @Date: 2023-03-31 15:05:57
 * @LastEditors: 申亚芳
 * @LastEditTime: 2024-05-16 10:11:38
 * @Description: file content
 * @FilePath: \cloud-build-website\src\views\open-consult.vue
-->
<template>
  <div class="page-content">
    <div class="menu-content pc">
      <MenuDemoPC></MenuDemoPC>
    </div>
    <div class="menu-content phone">
      <MenuDemoPhone></MenuDemoPhone>
    </div>
    <div class="details">
      <div class="container">
        <div class="title_h4">
          <span>开户咨询</span>
          <i></i>
        </div>
        <img
          class="consultImg"
          :src="require('@/assets/image/consult_img.png')"
          alt=""
        />
        <!-- 
        <div class="detailsImages">
          <img :src="require('@/assets/image/detail_img1.png')" alt="" />
          <img :src="require('@/assets/image/detail_img2.png')" alt="" />
          <img :src="require('@/assets/image/detail_img3.png')" alt="" />
        </div>
         -->
        <!-- 
        <div class="detailsContent">
          <div class="title_h1"><i></i>交易类机构会员开户咨询</div>
          <div class="title_h1"><i></i>交易自然人会员开户咨询</div>
          <div class="detailsPPText">
            使用碳普惠减排量实现大型活动/赛事/会议碳中和，出具碳中和证书。
          </div>
          <div class="title_h2">
            源自&nbsp;&nbsp;云链动态&nbsp;&nbsp;2019-02-24
          </div>
          <img
            :src="require('@/assets/image/detail_main_img.png')"
            alt=""
            class="detailsMainImg"
          />
        </div>
       -->
      </div>
    </div>
    <FooterDemo></FooterDemo>
  </div>
</template>
<script lang="ts">
/* eslint-disable */
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CommonApi } from '../core/http/Api';
import { HttpManager } from '../core/http/HttpManager';
import { HttpRequestConfig } from '../core/http/HttpRequestConfig';
import { HttpRequestParams } from '../core/http/HttpRequestParams';
import FooterDemo from '@/components/FooterDemo.vue';
import MenuDemoPC from '@/components/MenuDemoPC.vue';
import MenuDemoPhone from '@/components/MenuDemoPhone.vue';
export default defineComponent({
  name: 'page',
  components: {
    FooterDemo,
    MenuDemoPC,
    MenuDemoPhone,
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  created() {},
  mounted() {},
  methods: {},
  unmounted() {},
});
</script>
<style lang="less" scoped>
.page-content {
  img.consultImg {
    display: block;
    width: 900px;
    margin: auto;
    @media screen and (max-width: 750px) {
      width: 100%;
    }

  }
}
</style>

<!--
 * @Author: 申亚芳
 * @Date: 2023-03-31 15:05:57
 * @LastEditors: 申亚芳
 * @LastEditTime: 2024-05-17 14:33:40
 * @Description: file content
 * @FilePath: \cloud-build-website\src\views\home.vue
-->
<template>
  <div class="page-content">
    <div id="menuPC" class="menu-content pc">
      <MenuDemoPC></MenuDemoPC>
    </div>
    <div class="menu-content phone">
      <MenuDemoPhone></MenuDemoPhone>
    </div>
    <div class="banner pc">
      <!-- trigger="click" :height="bannerHeight + 'px'" -->
      <el-carousel interval="3000" height="600px">
        <el-carousel-item v-for="item in 1" :key="item">
          <div class="itemWrap">
            <img :src="require('@/assets/image/banner_bg.png')" alt="" />
            <div class="contentText">
              <!-- <img
                class="bannerImg"
                :src="require('@/assets/image/banner_img1.png')"
                alt=""
              /> -->
              <!-- 
              <img
                class="bannerText"
                :src="require('@/assets/image/banner_text1.png')"
                alt=""
              />
               -->
              <div class="bannerPPText">
                <img
                  :src="require('@/assets/image/banner_text.png')"
                  alt=""
                  class="bannerTextImg"
                />
                <!-- <div class="text_h1">低碳现在 绿色未来</div>
                <div class="blank"></div>
                <div class="text_h2">规划咨询、技术运维、融资租赁、供应链</div> -->
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="banner phone">
      <!-- trigger="click"-->
      <el-carousel interval="3000" height="450px">
        <el-carousel-item v-for="item in 1" :key="item">
          <div class="itemWrap">
            <img :src="require('@/assets/image/banner_bg.png')" alt="" />
            <div class="contentText">
              <!-- <img
                class="bannerImg"
                :src="require('@/assets/image/banner_img1.png')"
                alt=""
              /> -->
              <!--
              <img
                class="bannerText"
                :src="require('@/assets/image/banner_text1.png')"
                alt=""
              />
               -->
              <div class="bannerPPText">
                <!-- <div class="text_h1">低碳现在 绿色未来</div>
                <div class="blank"></div>
                <div class="text_h2">规划咨询、技术运维、融资租赁、供应链</div> -->
                <img
                  :src="require('@/assets/image/banner_text.png')"
                  alt=""
                  class="bannerTextImg"
                />
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="news">
      <div class="container">
        <div class="title_h4">
          <span>业务体系</span>
          <i></i>
        </div>
        <div v-if="false" class="currentNew">
          <div class="imageWrap flex-x-y">
            <img :src="require('@/assets/image/new_img.png')" />
          </div>
          <div class="contentNew">
            <div class="contentText">
              <div class="title">碳交易中介服务</div>
              <div class="content">
                碳排放配额及CCER开发及交易、碳资产托管服务、碳交易经济服务。综合解决方案规划
              </div>
            </div>
            <div class="time">
              <!-- 
              <span>5-9</span>
              <i>2024</i>
               -->
            </div>
          </div>
        </div>
        <ul>
          <li>
            <div class="time">
              <!-- 
              <span>5-9</span>
              <i>2024</i>
               -->
              <img :src="require('@/assets/image/icon_1.png')" alt="" />
            </div>
            <div class="ppText">
              <div class="title ellipsis">绿色培训服务</div>
              <div class="ppContent ellipsis-2">
                权威认证、精品课程、场景实践、按需定制
              </div>
            </div>
          </li>
          <li>
            <div class="time">
              <!-- <span>5-9</span>
              <i>2024</i> -->
              <img :src="require('@/assets/image/icon_4.png')" alt="" />
            </div>
            <div class="ppText">
              <div class="title ellipsis">开户咨询</div>
              <div class="ppContent ellipsis-2">
                交易类机构会员开户咨询、交易自然人会员开户咨询
              </div>
            </div>
          </li>
          <li>
            <div class="time">
              <!-- <span>5-9</span>
              <i>2024</i> -->
              <img :src="require('@/assets/image/icon_3.png')" alt="" />
            </div>
            <div class="ppText">
              <div class="title ellipsis">绿色咨询</div>
              <div class="ppContent ellipsis-2">
                碳管理体系建设、能力建设、资讯服务,碳资产(节能量、用能权、排污权等)管理服务,绿色机关、绿色社区、绿色校园、绿色医院、绿色景区、绿色商超、绿色园区、绿色建筑、绿色物流、绿色企业的低碳发展战略及规划
              </div>
            </div>
          </li>
          <li>
            <div class="time">
              <!-- <span>5-9</span>
              <i>2024</i> -->
              <img :src="require('@/assets/image/icon_6.png')" alt="" />
            </div>
            <div class="ppText">
              <div class="title ellipsis">绿色金融顾问</div>
              <div class="ppContent ellipsis-2">
                双碳政策解读、企业碳实务和碳金融实践等、绿色项目贷款咨询、绿色供应链金融咨询、绿色产业基金咨询、ESG
                投资咨询
              </div>
            </div>
          </li>
          <li>
            <div class="time">
              <!-- <span>5-9</span>
              <i>2024</i> -->
              <img :src="require('@/assets/image/icon_2.png')" alt="" />
            </div>
            <div class="ppText">
              <div class="title ellipsis">节能技改全周期方案设计</div>
              <div class="ppContent ellipsis-2">
                用能企业咨询诊断、节能技改方案设计、绿色供应商匹配、提供融资方案、资金对接、节能技改方案实施、优惠政策申报
              </div>
            </div>
          </li>
          <li>
            <div class="time">
              <!-- <span>5-9</span>
              <i>2024</i> -->
              <img :src="require('@/assets/image/icon_5.png')" alt="" />
            </div>
            <div class="ppText">
              <div class="title ellipsis">绿色公益</div>
              <div class="ppContent ellipsis-2">
                知识大赛、低碳活动、低碳会议
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="false" class="products">
      <div class="container">
        <div class="title_h4">
          <span>产品推荐</span>
          <i></i>
        </div>
        <ul>
          <li>
            <img :src="require('@/assets/image/product_img1.png')" alt="" />
            <div class="title">
              <span class="flex-x-y">产品标签</span>
              <i class="ellipsis">我是标题标题我是标题标题</i>
            </div>
            <div class="subText ellipsis">标签文本标签文本标签文本</div>
            <div class="bottom flex-y-s">
              <div class="price flex-y">
                <span>￥</span>
                <i>8889</i>
              </div>
              <div class="buyBtn flex-x-y pointer">购买</div>
            </div>
          </li>
          <li>
            <img :src="require('@/assets/image/product_img2.png')" alt="" />
            <div class="title">
              <span class="flex-x-y">产品标签</span>
              <i class="ellipsis">我是标题标题我是标题标题</i>
            </div>
            <div class="subText ellipsis">标签文本标签文本标签文本</div>
            <div class="bottom flex-y-s">
              <div class="price flex-y">
                <span>￥</span>
                <i>8889</i>
              </div>
              <div class="buyBtn flex-x-y pointer">购买</div>
            </div>
          </li>
          <li>
            <img :src="require('@/assets/image/product_img3.png')" alt="" />
            <div class="title">
              <span class="flex-x-y">产品标签</span>
              <i class="ellipsis">我是标题标题我是标题标题</i>
            </div>
            <div class="subText ellipsis">标签文本标签文本标签文本</div>
            <div class="bottom flex-y-s">
              <div class="price flex-y">
                <span>￥</span>
                <i>8889</i>
              </div>
              <div class="buyBtn flex-x-y pointer">购买</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="false" class="companyIdea">
      <div class="container">
        <div class="title_h4">
          <span>设立背景</span>
          <i></i>
        </div>
        <div class="introduce">
          <p>
            2020年习近平总书记向世界庄严宣布了中国碳达峰目标及碳中和愿景，我国将采取更加有力的政策和措施，使二氧化碳排放量力争于2030年前达到峰值，努力争取2060年实现碳中和。同年，在中央经济工作会议中，首次将“碳达峰、碳中和”列入重点工作，各级政府及各部委出台了一系列相关政策，并印发了《关于统筹和加强应对气候变化与生态保护相关工作的指导意见》,从顶层设计推动降碳战略统筹规划，重点要求加紧制定2030年前二氧化碳达峰行动方案，加快推进全国碳排放权交易市场建设。
          </p>
          <p>
            2021年上海能源交易所发布了《关于全国碳排放权交易相关事项的公告》,要求国内当前所有的碳排放
            (CEA) 交易都应通过交易所进行，并于2021年7月16日正式开放交易。
          </p>
        </div>
        <!-- 
        <div class="images">
          <img :src="require('@/assets/image/company_img1.png')" alt="" />
          <img :src="require('@/assets/image/company_img2.png')" alt="" />
          <img :src="require('@/assets/image/company_img3.png')" alt="" />
        </div>
         -->
      </div>
    </div>
    <div v-if="false" class="contactUs">
      <div class="container">
        <div class="title_h4">
          <span>联系我们</span>
          <i></i>
        </div>
        <div class="content">
          <div class="images">
            <img :src="require('@/assets/image/contact_us_img1.png')" />
          </div>
          <div class="frr">
            <div class="title">联系我们</div>
            <div class="mywrap">
              <div class="texts">
                <div class="item flex-y">
                  <span class="flex-y">
                    <img
                      :src="require('@/assets/image/company_name.png')"
                      alt=""
                    />
                    <b>公司名称</b>
                  </span>
                  <i>网雀湖北科技有限公司</i>
                </div>
                <div class="item flex-y">
                  <span class="flex-y">
                    <img
                      :src="require('@/assets/image/company_tel.png')"
                      alt=""
                    />
                    <b>联系电话</b>
                  </span>
                  <i>027-85518717</i>
                </div>
                <div class="item flex-y">
                  <span class="flex-y">
                    <img
                      :src="require('@/assets/image/company_email.png')"
                      alt=""
                    />
                    <b>联系邮箱</b>
                  </span>
                  <i>vinni999@sina.com</i>
                </div>
                <div class="item flex-y">
                  <span class="flex-y">
                    <img
                      :src="require('@/assets/image/company_address.png')"
                      alt=""
                    />
                    <b>公司地址</b>
                  </span>
                  <i>武汉市武昌区中北路223号中碳登大厦10楼</i>
                </div>
              </div>
              <div class="code">
                <img :src="require('@/assets/image/code.png')" alt="" />
                <span>微信二维码</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="footer">
      <FooterDemo></FooterDemo>
    </div>
  </div>
</template>
<script lang="ts">
/* eslint-disable */
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CommonApi } from '../core/http/Api';
import { HttpManager } from '../core/http/HttpManager';
import { HttpRequestConfig } from '../core/http/HttpRequestConfig';
import { HttpRequestParams } from '../core/http/HttpRequestParams';
import FooterDemo from '@/components/FooterDemo.vue';
import MenuDemoPC from '@/components/MenuDemoPC.vue';
import MenuDemoPhone from '@/components/MenuDemoPhone.vue';
export default defineComponent({
  name: 'Home',
  components: {
    FooterDemo,
    MenuDemoPC,
    MenuDemoPhone,
  },
  setup() {
    const router = useRouter();
    const bannerHeight: any = ref(0);
    // const newsArray: any = ref([
    //   {
    //     time: '10-19',
    //     year: '2020',
    //     title: '中国通信工业协会区块链专家委员会莅临云',
    //     content:
    //       '中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临云中国通信工业协会',
    //   },
    //   {
    //     time: '10-19',
    //     year: '2020',
    //     title: '中国通信工业协会区块链专家委员会莅临云',
    //     content:
    //       '中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临云中国通信工业协会',
    //   },
    //   {
    //     time: '10-19',
    //     year: '2020',
    //     title: '中国通信工业协会区块链专家委员会莅临云',
    //     content:
    //       '中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临云中国通信工业协会',
    //   },
    //   {
    //     time: '10-19',
    //     year: '2020',
    //     title: '中国通信工业协会区块链专家委员会莅临云',
    //     content:
    //       '中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临云中国通信工业协会',
    //   },
    // ]);
    return {
      router,
      // newsArray,
      bannerHeight,
    };
  },
  created() {
    // var userAgentInfo = navigator.userAgent;
    // var Agents = [
    //   'Android',
    //   'iPhone',
    //   'SymbianOS',
    //   'Windows Phone',
    //   'iPad',
    //   'iPod',
    // ];
    // var flagPc = true;
    // for (var v = 0; v < Agents.length; v++) {
    //   if (userAgentInfo.indexOf(Agents[v]) > 0) {
    //     flagPc = false;
    //     break;
    //   }
    // }
    // if (flagPc) {
    //   console.log('pc端');
    //   var screenHeight = window.innerHeight;
    //   // var menuPCScrollHeight = document.getElementById('menuPC').scrollHeight;
    //   // var footerScrollHeight = document.getElementById('footer').scrollHeight;
    //   // console.log('menuPCScrollHeight:', menuPCScrollHeight);
    //   // console.log('footerScrollHeight:', footerScrollHeight);
    //   this.bannerHeight = screenHeight - 114 - 303;
    //   // this.bannerHeight = screenHeight - menuPCScrollHeight - footerScrollHeight;
    // }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      //获取滚动时的高度
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 0) {
        this.isScrollFlag = true;
        //滚动大于0的时候要做的操作
      } else {
        this.isScrollFlag = false;
      }
      // console.log('scrollTop:', scrollTop);
    },
  },
  unmounted() {
    document.removeEventListener('scroll', this.handleScroll);
  },
});
</script>
<style lang="less" scoped>
.page-content {
  //首页一屏样式
  // overflow-y: hidden;
  // box-sizing: border-box;
  // height: 100vh;
  @media screen and (max-width: 750px) {
    overflow-y: auto;
  }
  .banner {
    width: 100%;
    // height: calc(100vh - 57px - 190px);
    // height: 700px;
    // background: #82bb4f !important;
    box-sizing: border-box;
    margin-bottom: 50px;
    @media screen and (max-width: 750px) {
      height: 450px;
      margin-bottom: 4px;
    }
    .itemWrap {
      width: 100%;
      height: 100%;
      position: relative;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media screen and (max-width: 750px) {
          object-fit: cover;
        }
      }
      .contentText {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        right: 0;
        box-sizing: border-box;
        display: flex;
        // justify-content: space-between;
        justify-content: center;
        align-items: flex-start;
        box-sizing: border-box;
        // padding: 103px 326px;
        box-sizing: border-box;
        padding-top: 40px;
        @media screen and (max-width: 750px) {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          padding: 40px 0 0 0;
          box-sizing: border-box;
        }
        .bannerImg {
          width: 555px;
          height: 394px;
          @media screen and (max-width: 750px) {
            width: 264px;
            height: 187px;
          }
        }
        .bannerText {
          width: 560px;
          height: 119px;
          @media screen and (max-width: 750px) {
            width: 280px;
            height: 60px;
          }
        }
        .bannerPPText {
          display: flex;
          // flex-direction: column;
          // box-sizing: border-box;
          // align-items: center;
          // justify-content: center;
          box-sizing: border-box;
          padding-right: 150px;
          @media screen and (max-width: 750px) {
            padding-left: 0;
            padding-right: 0;
          }
          .bannerTextImg {
            box-sizing: border-box;
            width: 900px;
            padding-top: 30px;
            @media screen and (max-width: 750px) {
              width: 100%;
            }
          }
          .text_h1 {
            font-size: 36px;
            // color: rgba(255, 255, 255, 0.9);
            color: #257e16;
            @media screen and (max-width: 750px) {
              font-size: 18px;
            }
          }
          .text_h2 {
            font-size: 20px;
            // color: rgba(255, 255, 255, 0.7);
            color: #257e16;
            @media screen and (max-width: 750px) {
              font-size: 14px;
            }
          }
          .blank {
            height: 20px;
            @media screen and (max-width: 750px) {
              height: 10px;
            }
          }
        }
      }
    }
    /deep/ .el-carousel__indicators--horizontal {
      display: none !important;
      bottom: 30px;
      @media screen and (max-width: 750px) {
        bottom: 8px;
      }
    }
  }
  .news {
    width: 100%;
    background: #fff;
    padding-bottom: 88px;
    @media screen and (max-width: 750px) {
      padding-bottom: 22px;
    }
    .currentNew {
      width: 100%;
      height: 209px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 32px;
      @media screen and (max-width: 750px) {
        height: auto;
        flex-direction: column;
        justify-content: center;
      }
      .imageWrap {
        width: 258px;
        min-width: 258px;
        height: 209px;
        border-radius: 4px;
        overflow: hidden;
        @media screen and (max-width: 750px) {
          width: 100%;
          max-width: 100%;
          height: 209px;
          margin: auto;
          border-radius: 0px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .contentNew {
        width: calc(100% - 278px);
        height: 100%;
        background: #f3f7ff;
        box-sizing: border-box;
        padding: 48px 24px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @media screen and (max-width: 750px) {
          width: 100%;
          flex-direction: column-reverse;
          justify-content: center;
          padding: 24px 16px;
        }
        .contentText {
          flex: 1;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-items: flex-start;
          padding-right: 30px;
          box-sizing: border-box;
          @media screen and (max-width: 750px) {
            padding-right: 0;
          }
          .title {
            font-weight: 500;
            font-size: 18px;
            color: #1d2023;
            margin-bottom: 10px;
          }
          .content {
            font-weight: 400;
            font-size: 14px;
            color: #606972;
            line-height: 26px;
          }
        }
        .time {
          display: flex;
          flex-direction: column;
          @media screen and (max-width: 750px) {
            margin-bottom: 20px;
          }
          span {
            font-size: 32px;
            color: #3f454b;
          }
          i {
            font-size: 20px;
            color: #b0b9c3;
          }
        }
      }
    }
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 750px) {
        display: unset;
        flex-wrap: unset;
      }
      li {
        width: calc((100% - 62px) / 2);
        height: 99px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 26px;
        @media screen and (max-width: 750px) {
          width: 100%;
          height: auto;
          margin-bottom: 20px;
        }
        .time {
          width: 108px;
          min-width: 108px;
          height: 99px;
          background: #f3f7ff;
          box-sizing: border-box;
          padding: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          // align-items: flex-end;
          // flex-direction: column;
          border-radius: 4px;
          img {
            width: 60px;
            height: 60px;
          }

          // span {
          //   font-size: 24px;
          //   color: #3f454b;
          // }
          // i {
          //   font-size: 16px;
          //   color: #b0b9c3;
          // }
        }
        .ppText {
          flex: 1;
          width: calc(100% - 108px);
          box-sizing: border-box;
          // padding: 22px 110px 22px 20px;
          padding: 0px 0px 0px 22px;
          height: 99px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          @media screen and (max-width: 750px) {
          }
          .title {
            width: 100%;
            font-weight: 400;
            font-size: 20px;
            color: #1d2023;
            // margin-bottom: 11px;
          }
          .ppContent {
            font-weight: 400;
            font-size: 16px;
            color: #78828c;
            line-height: 22px;
          }
        }
      }
    }
  }
  .products {
    width: 100%;
    background: #f0f5ff;
    .container {
      ul {
        width: 100%;
        height: 378px;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 750px) {
          display: unset;
          flex-direction: column;
        }
        li {
          width: calc((100% - 108px) / 3);
          height: 378px;
          @media screen and (max-width: 750px) {
            width: 100%;
          }
          img {
            display: block;
            width: 100%;
            height: 258px;
            border-radius: 4px;
            margin-bottom: 20px;
            object-fit: cover;
          }
          .title {
            display: flex;
            align-items: center;
            width: 100%;
            span {
              width: 52px;
              height: 18px;
              border-radius: 2px;
              border: 1px solid #ea3d1d;
              box-sizing: border-box;
              font-weight: 400;
              font-size: 10px;
              color: #ea3d1d;
            }
            i {
              width: calc(100% - 52px);
              font-weight: 400;
              font-size: 14px;
              color: #1d2023;
              padding-left: 16px;
              box-sizing: border-box;
            }
          }
          .subText {
            width: 100%;
          }
          .bottom {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .price {
              font-size: 12px;
              color: #1d2023;
              font-weight: 500;
              span {
                font-size: 12px;
                color: #1d2023;
              }
              i {
                font-size: 24px;
                color: #1d2023;
              }
            }
            .buyBtn {
              width: 96px;
              height: 32px;
              background: #ff5e5e;
              border-radius: 4px;
              font-weight: 400;
              font-size: 12px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .companyIdea {
    width: 100%;
    background: #fff;
    margin-bottom: 44px;
    @media screen and (max-width: 750px) {
      margin-bottom: 0px;
    }
    .introduce {
      width: 100%;
      margin-bottom: 88px;
      p {
        font-weight: 400;
        font-size: 16px;
        color: #606972;
        line-height: 26px;
        text-indent: 2em;
      }
      @media screen and (max-width: 750px) {
        margin-bottom: 22px;
      }
    }
    .images {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 750px) {
        display: unset;
      }
      img {
        width: calc((100% - 108px) / 3);
        height: 258px;
        border-radius: 4px;
        @media screen and (max-width: 750px) {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
  .contactUs {
    width: 100%;
    margin-bottom: 44px;
    @media screen and (max-width: 750px) {
      margin-bottom: 22px;
    }
    .content {
      display: flex;
      @media screen and (max-width: 750px) {
        display: unset;
      }
      .images {
        width: 360px;
        height: 270px;
        border-radius: 4px;
        overflow: hidden;
        @media screen and (max-width: 750px) {
          width: 100%;
          height: auto;
        }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .frr {
        flex: 1;
        box-sizing: border-box;
        padding: 30px 0 30px 60px;
        @media screen and (max-width: 750px) {
          padding: 30px 0 30px 0px;
        }
        .title {
          font-weight: 500;
          font-size: 16px;
          color: #1d2023;
          margin-bottom: 40px;
        }
        .mywrap {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          @media screen and (max-width: 750px) {
            display: unset;
          }
          .texts {
            .item {
              display: flex;
              align-items: center;
              margin-bottom: 22px;
              span {
                margin-right: 28px;
                width: 76px;
                img {
                  width: 14px;
                  height: 14px;
                  margin-right: 4px;
                }
              }
              i {
                font-weight: 400;
                font-size: 14px;
                color: #000000;
              }
            }
          }
          .code {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 750px) {
              // align-items: flex-start;
            }
            img {
              width: 92px;
              height: 92px;
              margin-bottom: 14px;
            }
            span {
              font-size: 12px;
              color: #535353;
            }
          }
        }
      }
    }
  }
}
</style>

<!--
 * @Author: 申亚芳
 * @Date: 2023-03-31 15:05:57
 * @LastEditors: 申亚芳
 * @LastEditTime: 2024-05-16 11:31:23
 * @Description: file content
 * @FilePath: \cloud-build-website\src\views\technology-improve.vue
-->
<template>
  <div class="page-content">
    <div class="menu-content pc">
      <MenuDemoPC></MenuDemoPC>
    </div>
    <div class="menu-content phone">
      <MenuDemoPhone></MenuDemoPhone>
    </div>
    <div class="details">
      <div class="container">
        <div class="title_h4">
          <span>节能技改全周期方案设计</span>
          <i></i>
        </div>
        <!-- <div class="infoTitle">
          <span class="left"></span> <i>节能技改全周期方案设计</i>
          <span class="right"></span>
        </div> -->
        <div class="jgContent">
          <div class="colorBg">
            <span>节能技改全周期方案设计</span>
            <i>用能企业咨询诊断</i>
            <i>节能技改方案设计</i>
            <i>绿色供应商匹配</i>
            <i>提供融资方案</i>
            <i>资金对接</i>
            <i>节能技改方案实施</i>
            <i>优惠政策申报</i>
          </div>
          <div class="imageWrap flex-x-y">
            <img :src="require('@/assets/image/jg_img.jpeg')" />
          </div>
        </div>
        <!-- 
        <div class="detailsImages">
          <img :src="require('@/assets/image/detail_img1.png')" alt="" />
          <img :src="require('@/assets/image/detail_img2.png')" alt="" />
          <img :src="require('@/assets/image/detail_img3.png')" alt="" />
        </div>
        <div class="detailsContent">
          <div class="title_h1"><i></i>用能企业咨询诊断</div>
          <div class="title_h1"><i></i>节能技改方案设计</div>
          <div class="title_h1"><i></i>绿色供应商匹配</div>
          <div class="title_h1"><i></i>提供融资方案</div>
          <div class="title_h1"><i></i>资金对接</div>
          <div class="title_h1"><i></i>节能技改方案实施</div>
          <div class="title_h1"><i></i>优惠政策申报</div>
          <div class="detailsPPText">
            使用碳普惠减排量实现大型活动/赛事/会议碳中和，出具碳中和证书。
          </div>
          <div class="title_h2">
            源自&nbsp;&nbsp;云链动态&nbsp;&nbsp;2019-02-24
          </div>
          <img
            :src="require('@/assets/image/detail_main_img.png')"
            alt=""
            class="detailsMainImg"
          />
        </div>
        -->
      </div>
    </div>
    <FooterDemo></FooterDemo>
  </div>
</template>
<script lang="ts">
/* eslint-disable */
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CommonApi } from '../core/http/Api';
import { HttpManager } from '../core/http/HttpManager';
import { HttpRequestConfig } from '../core/http/HttpRequestConfig';
import { HttpRequestParams } from '../core/http/HttpRequestParams';
import FooterDemo from '@/components/FooterDemo.vue';
import MenuDemoPC from '@/components/MenuDemoPC.vue';
import MenuDemoPhone from '@/components/MenuDemoPhone.vue';
export default defineComponent({
  name: 'page',
  components: {
    FooterDemo,
    MenuDemoPC,
    MenuDemoPhone,
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  created() {},
  mounted() {},
  methods: {},
  unmounted() {},
});
</script>
<style lang="less" scoped>
.page-content {
  .jgContent {
    width: 1200px;
    height: 380px;
    display: flex;
    margin: auto;
    @media screen and (max-width: 750px) {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      height: auto;
      margin-bottom: 88px;
    }

    .colorBg {
      width: 400px;
      height: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding-left: 30px;
      padding-top: 30px;
      padding-bottom: 30px;
      @media screen and (max-width: 750px) {
        width: 100%;
      }
      // background: linear-gradient(
      //   to right,
      //   rgba(47, 116, 255, 0.4),
      //   rgba(47, 116, 255, 1)
      // );
      // background: linear-gradient(to right, #1aabff, #1a8cff);
      background: linear-gradient(to right, #92d050, #00b050);
      span {
        color: #fff;
        font-size: 28px;
        margin-bottom: 16px;
        font-weight: bold;
      }
      i {
        color: #fff;
        font-size: 20px;
        margin-bottom: 6px;
      }
    }
    .imageWrap {
      flex: 1;
      height: 100%;
      @media screen and (max-width: 750px) {
        width: 100%;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>

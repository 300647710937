<!--
 * @Author: 申亚芳
 * @Date: 2023-03-31 15:05:57
 * @LastEditors: 申亚芳
 * @LastEditTime: 2024-05-16 10:16:57
 * @Description: file content
 * @FilePath: \cloud-build-website\src\views\business-financial-advisor.vue
-->
<template>
  <div class="page-content">
    <div class="menu-content pc">
      <MenuDemoPC></MenuDemoPC>
    </div>
    <div class="menu-content phone">
      <MenuDemoPhone></MenuDemoPhone>
    </div>
    <div class="details">
      <div class="container">
        <div class="title_h4">
          <span>绿色金融顾问</span>
          <i></i>
        </div>
        <!-- <div class="infoTitle">
          <span class="left"></span> <i>绿色金融顾问</i>
          <span class="right"></span>
        </div> -->
        <div class="gwWrap">
          <div class="gwBox">
            <img :src="require('@/assets/image/gw_img1.png')" alt="" />
            <span>双碳政策解读、企业碳实务和碳金融实践等</span>
          </div>
          <div class="gwBox">
            <img :src="require('@/assets/image/gw_img2.png')" alt="" />
            <span>绿色项目贷款咨询</span>
          </div>
          <div class="gwBox">
            <img :src="require('@/assets/image/gw_img3.png')" alt="" />
            <span>绿色供应链金融咨询</span>
          </div>
          <div class="gwBox">
            <img :src="require('@/assets/image/gw_img4.png')" alt="" />
            <span>绿色产业基金咨询</span>
          </div>
          <div class="gwBox">
            <img :src="require('@/assets/image/gw_img5.png')" alt="" />
            <span>ESG 投资咨询</span>
          </div>
        </div>
        <!-- 
        <div class="detailsImages">
          <img :src="require('@/assets/image/detail_img1.png')" alt="" />
          <img :src="require('@/assets/image/detail_img2.png')" alt="" />
          <img :src="require('@/assets/image/detail_img3.png')" alt="" />
        </div>
        <div class="detailsContent">
          <div class="title_h1">
            <i></i>双碳政策解读、企业碳实务和碳金融实践等
          </div>
          <div class="title_h1"><i></i>绿色项目贷款咨询</div>
          <div class="title_h1"><i></i>绿色供应链金融咨询</div>
          <div class="title_h1"><i></i>绿色产业基金咨询</div>
          <div class="title_h1"><i></i>ESG 投资咨询</div>
          <div class="detailsPPText">
            使用碳普惠减排量实现大型活动/赛事/会议碳中和，出具碳中和证书。
          </div>
          <div class="title_h2">
            源自&nbsp;&nbsp;云链动态&nbsp;&nbsp;2019-02-24
          </div>
          <img
            :src="require('@/assets/image/detail_main_img.png')"
            alt=""
            class="detailsMainImg"
          />
        </div>
        -->
      </div>
    </div>
    <FooterDemo></FooterDemo>
  </div>
</template>
<script lang="ts">
/* eslint-disable */
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CommonApi } from '../core/http/Api';
import { HttpManager } from '../core/http/HttpManager';
import { HttpRequestConfig } from '../core/http/HttpRequestConfig';
import { HttpRequestParams } from '../core/http/HttpRequestParams';
import FooterDemo from '@/components/FooterDemo.vue';
import MenuDemoPC from '@/components/MenuDemoPC.vue';
import MenuDemoPhone from '@/components/MenuDemoPhone.vue';
export default defineComponent({
  name: 'page',
  components: {
    FooterDemo,
    MenuDemoPC,
    MenuDemoPhone,
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  created() {},
  mounted() {},
  methods: {},
  unmounted() {},
});
</script>
<style lang="less" scoped>
.page-content {
  .details {
    .gwWrap {
      width: 1000px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: auto;
      margin-bottom: 16px;
      @media screen and (max-width: 750px) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      }
      .gwBox {
        display: flex;
        flex-direction: column;
        width: 200px;
        align-items: center;
        @media screen and (max-width: 750px) {
          width: calc(100% / 2);
        }
        img {
          width: 150px;
          @media screen and (max-width: 750px) {
            width: 130px;
          }
        }
        span {
          width: 100%;
          font-size: 16px;
          box-sizing: border-box;
          padding: 20px;
          text-align: center;
        }
      }
    }
  }
}
</style>

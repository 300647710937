<!--
 * @Author: 申亚芳
 * @Date: 2023-03-31 15:05:57
 * @LastEditors: 申亚芳
 * @LastEditTime: 2024-05-16 09:47:38
 * @Description: file content
 * @FilePath: \cloud-build-website\src\components\FooterDemo.vue
-->
<template>
  <div class="footer">
    <div class="container">
      <div class="links">
        <ul v-for="(item, index) in menuData" :key="index">
          <li
            v-for="(okey, i) in item.children"
            :key="i"
            @click="secondClick(index, okey)"
          >
            {{ index == 0 ? okey.pname : okey.name }}
          </li>
        </ul>
        <!-- <ul>
          <li
            v-for="(item, index) in menuData"
            :key="index"
            @click="firstClick(index, item)"
          >
            {{ item.pname }}
          </li>
        </ul> -->
      </div>
      <!-- <div class="copyRights">Copyright © 2020</div> -->
      <div class="copyRightsText">
        Copyright ©
        2020&nbsp;|&nbsp;网雀湖北科技有限公司&nbsp;|&nbsp;鄂ICP备2022010664号-1
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CommonApi } from '../core/http/Api';
import { HttpManager } from '../core/http/HttpManager';
import { HttpRequestConfig } from '../core/http/HttpRequestConfig';
import { HttpRequestParams } from '../core/http/HttpRequestParams';
import { useStore } from 'vuex';
// import vueQr from 'vue-qr/src/packages/vue-qr.vue';
export default defineComponent({
  name: 'PAGE_NAME',
  components: {
    // vueQr,
  },
  props: {},
  setup() {
    const router = useRouter();
    const store = useStore();
    const menuData: any = ref([
      {
        pname: '首页',
        pid: 'b_00',
        dropdown: false,
        purl: '/',
        children: [],
      },
      // {
      //   pname: '碳咨询',
      //   pid: 'a_00',
      //   dropdown: false,
      //   purl: 'null',
      //   children: [
      //     {
      //       name: '企业碳资产管理',
      //       id: 'a_00_01',
      //       url: '/carbon-company-manage',
      //     },
      //     {
      //       name: '双碳咨询服务',
      //       id: 'a_00_02',
      //       url: '/carbon-double-consult',
      //     },
      //     {
      //       name: 'ESG信息披露',
      //       id: 'a_00_03',
      //       url: '/public-ESG',
      //     },
      //   ],
      // },
      // {
      //   pname: '碳金融',
      //   pid: 'a_01',
      //   dropdown: false,
      //   purl: null,
      //   children: [
      //     {
      //       name: '绿色企业（项目）评价',
      //       id: 'a_01_01',
      //       url: '/company-evaluate',
      //     },
      //     {
      //       name: '项目减排量核算服务',
      //       id: 'a_01_02',
      //       url: '/emission-reduction-check',
      //     },
      //     {
      //       name: '节能技改一体化金融融资服务',
      //       id: 'a_01_03',
      //       url: '/financial-asset-service',
      //     },
      //     {
      //       name: '绿色项目申报',
      //       id: 'a_01_04',
      //       url: '/project-apply',
      //     },
      //     {
      //       name: '绿色金融产品对接',
      //       id: 'a_01_05',
      //       url: '/product-join',
      //     },
      //   ],
      // },
      // {
      //   pname: '节能技改',
      //   pid: 'a_02',
      //   dropdown: false,
      //   purl: '/saving-energy',
      //   children: [],
      // },
      // {
      //   pname: '设备配套',
      //   pid: 'a_03',
      //   dropdown: false,
      //   purl: '/equipment-match',
      //   children: [],
      // },
      // {
      //   pname: '碳普惠',
      //   pid: 'a_04',
      //   dropdown: false,
      //   purl: null,
      //   children: [
      //     {
      //       name: '碳普惠平台的企业端接入',
      //       id: 'a_04_01',
      //       url: '/carbon-company-join',
      //     },
      //     {
      //       name: '碳普惠方法学开发',
      //       id: 'a_04_02',
      //       url: '/carbon-method-develop',
      //     },
      //     {
      //       name: '碳中和大型活动',
      //       id: 'a_04_03',
      //       url: '/carbon-activity',
      //     },
      //     {
      //       name: '地方政府碳普惠平台开发建设',
      //       id: 'a_04_04',
      //       url: '/carbon-platform-develop',
      //     },
      //     {
      //       name: '整县推动生态价值实现',
      //       id: 'a_04_05',
      //       url: '/ecological-value',
      //     },
      //   ],
      // },
      {
        pname: '公司介绍',
        pid: 'a_06',
        dropdown: false,
        purl: '/company-introduce',
        children: [],
      },
      {
        pname: '业务体系1',
        pid: 'a_07',
        dropdown: false,
        purl: '',
        children: [
          {
            name: '绿色培训服务',
            id: 'a_07_01',
            url: '/business-train',
          },
          {
            name: '开户咨询',
            id: 'a_07_02',
            url: '/open-consult',
          },
          {
            name: '绿色咨询',
            id: 'a_07_03',
            url: '/consult',
          },
          // {
          //   name: '绿色金融顾问',
          //   id: 'a_07_04',
          //   url: '/financial-advisor',
          // },
          // {
          //   name: '节能技改全周期方案设计',
          //   id: 'a_07_05',
          //   url: '/technology-improve',
          // },
          // {
          //   name: '绿色公益',
          //   id: 'a_07_06',
          //   url: '/public-benefit',
          // },
        ],
      },

      {
        pname: '业务体系2',
        pid: 'a_07',
        dropdown: false,
        purl: '',
        children: [
          // {
          //   name: '绿色培训服务',
          //   id: 'a_07_01',
          //   url: '/business-train',
          // },
          // {
          //   name: '开户咨询',
          //   id: 'a_07_02',
          //   url: '/open-consult',
          // },
          // {
          //   name: '绿色咨询',
          //   id: 'a_07_03',
          //   url: '/consult',
          // },
          {
            name: '绿色金融顾问',
            id: 'a_07_04',
            url: '/financial-advisor',
          },
          {
            name: '节能技改全周期方案设计',
            id: 'a_07_05',
            url: '/technology-improve',
          },
          {
            name: '绿色公益',
            id: 'a_07_06',
            url: '/public-benefit',
          },
        ],
      },

      {
        pname: '联系我们',
        pid: 'a_05',
        dropdown: false,
        purl: '/about-us',
        children: [],
      },
    ]);
    function secondClick(index: number, obj: any) {
      //多个二级菜单、一级菜单重组
      if (index == 0) {
        //只有一级菜单，就用重组数据
        sessionStorage.setItem('pid', obj.pid);
        router.push(obj.purl);
      } else {
        sessionStorage.setItem('pid', menuData.value[index].pid);
        router.push(obj.url);
      }
    }
    function firstClick(index: number, obj: any) {
      sessionStorage.setItem('pid', menuData.value[index].pid);
      router.push(obj.purl);
    }
    onMounted(() => {
      let temArray: any = [];
      menuData.value.forEach((e: any) => {
        if (e.children.length == 0) {
          temArray.push(e);
        }
      });
      menuData.value = menuData.value.filter((e: any) => {
        return e.children.length > 0;
      });
      let obj: any = {
        pname: '重组',
        pid: 'b_00',
        dropdown: false,
        purl: '',
        children: temArray,
      };
      menuData.value.unshift(obj);
      console.log('重组后的数组：', menuData.value);
    });
    return {
      router,
      store,
      menuData,
      firstClick,
      secondClick,
    };
  },
  methods: {},
});
</script>
<style lang="less" scoped>
.footer {
  width: 100%;
  // background: #1b1d22;
  background: #82bb4f;
  padding-top: 30px;
  padding-bottom: 20px;
  box-sizing: border-box;
  // height: 303px;
  // height: calc(100vh - 114px - 600px);
  @media screen and (max-width: 750px) {
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
    // height: calc(100vh - 57px - 400px);
  }
  .links {
    width: 900px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    @media screen and (max-width: 750px) {
      width: 100%;
      margin-bottom: 20px;
      flex-direction: column;
    }
    ul {
      width: 50%;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      // align-items: center;
      // margin: auto;
      @media screen and (max-width: 750px) {
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
      }
      li {
        font-weight: 400;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 12px;
        cursor: pointer;
        @media screen and (max-width: 750px) {
          align-items: center;
          min-width: 50%;
          width: 50%;
          text-align: center;
        }
      }
      // &:nth-of-type(1) {
      //   // width: 70px;
      //   @media screen and (max-width: 750px) {
      //     width: 50%;
      //   }
      // }
      // &:nth-of-type(2) {
      //   // flex: 1;
      //   // align-items: center;
      //   li {
      //     text-align: center;
      //   }
      //   @media screen and (max-width: 750px) {
      //     width: 50%;
      //   }
      // }
      // &:nth-of-type(3) {
      //   // width: 70px;
      //   @media screen and (max-width: 750px) {
      //     width: 50%;
      //   }
      // }
    }
  }
  .copyRights {
    font-weight: 400;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.48);
    text-align: center;
    margin-bottom: 12px;
  }
  .copyRightsText {
    font-weight: 400;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.48);
    text-align: center;
  }
}
</style>

<!--
 * @Author: 申亚芳
 * @Date: 2023-03-31 15:05:57
 * @LastEditors: 申亚芳
 * @LastEditTime: 2024-05-16 16:22:20
 * @Description: file content
 * @FilePath: \cloud-build-website\src\views\business-consult.vue
-->
<template>
  <div class="page-content">
    <div class="menu-content pc">
      <MenuDemoPC></MenuDemoPC>
    </div>
    <div class="menu-content phone">
      <MenuDemoPhone></MenuDemoPhone>
    </div>
    <div class="details">
      <div class="container">
        <div class="title_h4">
          <span>绿色咨询</span>
          <i></i>
        </div>
        <div class="detailsContent">
          <img
            class="consultImg1"
            :src="require('@/assets/image/consult_img11.png')"
          />
          <img
            class="consultImg2"
            :src="require('@/assets/image/consult_img22.png')"
          />
          <!-- <div class="ppTextTitle">企业绿色评价咨询</div> -->
          <img
            class="consultImg3"
            :src="require('@/assets/image/my_img4.png')"
          />
          <img
            class="consultImg4"
            :src="require('@/assets/image/my_img5.png')"
          />
          <!-- 
          <div class="title_h1"><i></i>绿色培训服务</div>
          <div class="title_h2"><i></i>碳管理体系建设、能力建设、资讯服务</div>
          <div class="title_h2">
            <i></i>碳资产(节能量、用能权、排污权等)管理服务
          </div>
          <div class="title_h2">
            <i></i
            >绿色机关、绿色社区、绿色校园、绿色医院、绿色景区、绿色商超、绿色园区、绿色建筑、绿色物流、绿色企业的低碳发展战略及规划
          </div>
          <div class="title_h2"><i></i>碳排放盘查、核查咨询</div>
          <div class="title_h2"><i></i>绿色活动的碳中和咨询</div>
          <div class="title_h2"><i></i>环境信息披露咨询服务</div>
          <div class="title_h2"><i></i>ESG信息披露咨询服务</div>
          <div class="title_h2">
            <i></i>产品碳足迹标识认证咨询（碳足迹标签 、碳减排标签
            、碳中和标签）
          </div>
          <div class="title_h2"><i></i>建筑类可持续标识认证咨询</div>
          <div class="title_h2">
            <i></i>绿证（可再生能源绿色电力证书）认证咨询
          </div>
          <div class="title_h2"><i></i>企业绿色评价咨询</div>
          <div class="imageWrap">
            <img
              :src="require('@/assets/image/my_img4.png')"
              alt=""
              class="detailsMainImg img4"
            />
            <img
              :src="require('@/assets/image/my_img5.png')"
              alt=""
              class="detailsMainImg img5"
            />
          </div> 
        --></div>
      </div>
    </div>
    <FooterDemo></FooterDemo>
  </div>
</template>
<script lang="ts">
/* eslint-disable */
import { defineComponent, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { CommonApi } from '../core/http/Api';
import { HttpManager } from '../core/http/HttpManager';
import { HttpRequestConfig } from '../core/http/HttpRequestConfig';
import { HttpRequestParams } from '../core/http/HttpRequestParams';
import FooterDemo from '@/components/FooterDemo.vue';
import MenuDemoPC from '@/components/MenuDemoPC.vue';
import MenuDemoPhone from '@/components/MenuDemoPhone.vue';
export default defineComponent({
  name: 'page',
  components: {
    FooterDemo,
    MenuDemoPC,
    MenuDemoPhone,
  },
  setup() {
    const router = useRouter();
    return {
      router,
    };
  },
  created() {},
  mounted() {},
  methods: {},
  unmounted() {},
});
</script>
<style lang="less" scoped>
.page-content {
  .details {
    .container {
      .detailsContent {
        padding-bottom: 88px;
        .consultImg1 {
          display: block;
          width: 1000px;
          margin: auto;
          margin-bottom: 88px;
          @media screen and (max-width: 750px) {
            width: 100%;
            margin-bottom: 44px;
          }
        }
        .consultImg2 {
          display: block;
          width: 1000px;
          margin: auto;
          margin-bottom: 88px;
          @media screen and (max-width: 750px) {
            width: 100%;
            margin-bottom: 44px;
          }
        }
        .consultImg3 {
          display: block;
          width: 1000px;
          margin: auto;
          margin-bottom: 44px;
          @media screen and (max-width: 750px) {
            width: 100%;
            margin-bottom: 22px;
          }
        }
        .consultImg4 {
          display: block;
          width: 1000px;
          margin: auto;
          @media screen and (max-width: 750px) {
            width: 100%;
          }
        }
        .ppTextTitle {
          font-size: 24px;
          text-align: center;
          margin-bottom: 16px;
          @media screen and (max-width: 750px) {
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
